import React from "react";
import "../assets/css/Footer.css"
export default function Footer () {
    return(

        <footer className="w3-black w3-center w3-opacity">
            <p>
                58/24 South West Boag Road
                T.Nagar, Chennai
                Chennai, TN 600017
            </p>
        </footer>
    );

}
