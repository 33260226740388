import React from "react";
import {Link} from "gatsby";
import "../assets/css/Navbar.css"

export default function Navbar() {
    //TODO: figure out a way to stop repeating the CSS classes in Link
        return(
            <nav>
                <ul className="main-nav pv-red w3" style={{"padding":0}}>
                    <li><Link to="/" className={"w3-bar-item w3-button w3-hide-small w3-padding-large w3-hover-white"}
                             activeClassName={" w3-padding-large w3-white"}>
                        Home
                    </Link></li>
                    <li><Link to="/sales/" className={"w3-bar-item w3-button w3-hide-small w3-padding-large w3-hover-white"}
                             activeClassName={" w3-padding-large w3-white"}>
                        Sales
                    </Link></li>
                    <li>
                        <Link to="/support/" className={"w3-bar-item w3-button w3-hide-small w3-padding-large w3-hover-white"}
                          activeClassName={" w3-padding-large w3-white"}>
                        Support
                        </Link>
                    </li>
                    <li>
                        <Link to="/contact/" className={"w3-bar-item w3-button w3-hide-small w3-padding-large w3-hover-white"}
                              activeClassName={" w3-padding-large w3-white"}>
                            Contact
                        </Link>
                    </li>
                </ul>
            </nav>
    );
}
