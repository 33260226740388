import React from "react";
import "../assets/css/w3.css"
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

export default function Sales() {
    return(
        <>
            <Navbar/>
            <div className="w3-row-padding w3-padding-64 w3-container">
                <div className="w3-content">
                    Need help choosing the right solution? Our friendly team of experts will be happy to assist you!
                    Contact us at <a href={"tel:+91-9444083187"}>+91-9444083187</a> or send us an email at <a href={"mailto:patanvenky@gmail.com"}>patanvenky@gmail.com</a>
                </div>
            </div>
            <Footer/>
        </>
    );
}
